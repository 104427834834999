import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/mark/di-net2/src/components/layout.js";
import ReactPlayer from 'react-player/lazy';
import Indexvid from '../../images/2022-01-19_14-02-57.mp4';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Index of DI`}</h1>
    <p><ReactPlayer url={Indexvid} muted={true} loop={true} playing={true} className='react-player' height='100%' width='100%' mdxType="ReactPlayer" /></p>
    <hr></hr>
    <p><a href="https://index.designinquiry.net" className="button">Visit Index of Design Inquiry</a></p>
    <p>{`This interactive web-based installation revisits the original Index of American Design, a New Deal work relief project for artists, imaginatively rebooting the project for the 21st century. The original project was a grandly ambitious project that indexed more than 18,000 U.S. objects, both handcrafted and factory produced and made between 1700 and 1900. DI’s project is just as ambitious, but uses digital tools to create an index that rejects curatorial hierarchies and cultivates broad participation. Anyone can contribute any kind of object, whether physical, digital, textual or conceptual. Each object in the index is represented through diverse kinds of data and metadata. This project is long-term, open-ended and game-changing.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      